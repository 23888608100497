import { dealsAPI } from '@/api/deals-api'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'

export const deals = {
    state: () => ({
        deals: [],
        dealsTotalCount: null,
        dealsLimit: 25,
        isEmptyDealsList: false,
        dealsLoading: false,
        statisticsLoading: false,
        dealsAverageAmount: null,
        dealsTotalAmount: null
    }),
    getters: {
        deals: (state) => state.deals,
        dealsCurrentCount: (state) => state.deals.length,
        dealsTotalCount: (state) => state.dealsTotalCount,
        dealsLimit: (state) => state.dealsLimit,
        isEmptyDealsList: (state) => state.isEmptyDealsList,
        dealsLoading: (state) => state.dealsLoading,
        statisticsLoading: (state) => state.statisticsLoading,
        dealsAverageAmount: (state) => state.dealsAverageAmount,
        dealsTotalAmount: (state) => state.dealsTotalAmount
    },
    mutations: {
        SET_DEALS(state, payload) {
            state.deals = [...state.deals, ...payload]
        },
        RESET_DEALS(state) {
            state.deals = []
        },
        SET_TOTAL_COUNT(state, payload) {
            state.dealsTotalCount = payload
        },
        SET_IS_EMPTY_DEALS_LIST(state, payload) {
            state.isEmptyDealsList = payload
        },
        UPDATE_DEAL_STAGE(state, updatedDeal) {
            state.deals = state.deals.map((deal) =>
                deal._id === updatedDeal._id ? updatedDeal : deal
            )

            pushNotification({
                message: `Stage successfully changed to <span>"${updatedDeal.stage}"</span>`,
                type: notificationTypes.SUCCESS
            })
        },
        SET_DEALS_LOADING(state, isLoading) {
            state.dealsLoading = isLoading
        },
        SET_STATISTICS_LOADING(state, isLoading) {
            state.statisticsLoading = isLoading
        },
        SET_DEALS_AVERAGE_AMOUNT(state, payload) {
            state.dealsAverageAmount = payload
        },
        SET_DEALS_TOTAL_AMOUNT(state, payload) {
            state.dealsTotalAmount = payload
        },
        ADD_NEW_DEAL(state, newDeal) {
            state.deals = [newDeal, ...state.deals]
        }
    },
    actions: {
        async submitDeal(cxt, data) {
            try {
                const res = await dealsAPI.submitDeal(data)
                console.log('submitDeal store res: ', res)
                if (res?.data) {
                    pushNotification({
                        message: `Deal <span>№${res.data._id}</span> successfully created`,
                        type: notificationTypes.SUCCESS
                    })

                    // add new deal
                    if (
                        cxt.state.deals.length &&
                        data.createdBy === cxt.getters.currentUser._id
                    ) {
                        cxt.commit('ADD_NEW_DEAL', res.data)
                    }

                    // update statistics
                    const newTotalCount = cxt.state.dealsTotalCount + 1
                    const newTotalAmount =
                        cxt.state.dealsTotalAmount + res.data.amount
                    const newAverageAmount = newTotalAmount / newTotalCount

                    if (cxt.state.dealsTotalCount) {
                        cxt.commit('SET_TOTAL_COUNT', newTotalCount)
                    }
                    if (cxt.state.dealsTotalAmount) {
                        cxt.commit('SET_DEALS_TOTAL_AMOUNT', newTotalAmount)
                    }
                    if (cxt.state.dealsAverageAmount) {
                        cxt.commit('SET_DEALS_AVERAGE_AMOUNT', newAverageAmount)
                    }

                    return res
                } else if (res?.errInfo.isError) {
                    return res
                } else {
                    throw new Error('Unknown error in submitting the deal')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async getDeals(cxt, params) {
            cxt.commit('SET_DEALS_LOADING', true)

            try {
                const res = await dealsAPI.fetchDeals(params)
                const data = res.data

                console.log('getDeals data: ', data)
                if (data?.results?.length && data?._meta[0]?.count) {
                    cxt.commit('SET_DEALS', data.results)
                    cxt.commit('SET_TOTAL_COUNT', data._meta[0].count)
                    cxt.commit('SET_IS_EMPTY_DEALS_LIST', false)
                } else if (!data?._meta[0]?.count && !res?.errInfo?.isError) {
                    cxt.commit('SET_IS_EMPTY_DEALS_LIST', true)
                } else {
                    throw new Error('Unknown error in fetching the deals')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }

            cxt.commit('SET_DEALS_LOADING', false)
        },
        async setDealStatistics(cxt, params) {
            cxt.commit('SET_STATISTICS_LOADING', true)
            try {
                const res = await dealsAPI.fetchDealStatistics(params)
                const data = res?.data

                if (data?.count) {
                    cxt.commit('SET_TOTAL_COUNT', data.count)
                } else {
                    // pushNotification({
                    //     message: `Failed to get the number of deals`,
                    //     type: notificationTypes.ERROR
                    // })
                }

                if (data?.average) {
                    cxt.commit('SET_DEALS_AVERAGE_AMOUNT', data.average)
                } else {
                    // pushNotification({
                    //     message: `Failed to get the average amount of deals`,
                    //     type: notificationTypes.ERROR
                    // })
                }

                if (data?.total) {
                    cxt.commit('SET_DEALS_TOTAL_AMOUNT', data.total)
                } else {
                    // pushNotification({
                    //     message: `Failed to get the total amount of deals`,
                    //     type: notificationTypes.ERROR
                    // })
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
            cxt.commit('SET_STATISTICS_LOADING', false)
        },
        async updateDealStage(cxt, payload) {
            try {
                const updatedDeal = await dealsAPI.updateDealStage(payload)

                console.log('STORE updatedDeal: ', updatedDeal)

                if (updatedDeal && Object.keys(updatedDeal).length) {
                    cxt.commit('UPDATE_DEAL_STAGE', updatedDeal)
                } else {
                    throw new Error(
                        "Unknown error in updating the deal's stage"
                    )
                }
            } catch (err) {
                pushNotification({
                    message: `Failed to update field "Stage". Error: <span>${err.message}</span>`,
                    type: notificationTypes.ERROR
                })
            }
        }
    }
}
